import {Page} from "../../../components/PrivateRoutePage"
import {Layout} from "../../../components/Layout"
import {ResponsiveContainer, SectionContainer, StarsImageContainer} from "../../../components/Shared"
import {useLocation} from "@reach/router"
import {useDispatch} from "react-redux"
import React, {useEffect, useState} from "react"
import {notification} from "../../notification/notificationSlice"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from "@material-ui/core"
import {PatreonTitleGrid} from "../Shared"
import {TransMdTypo} from "../../../components/i18n/TransMdTypo"
import {MdVisibilityOff} from "@react-icons/all-files/md/MdVisibilityOff"
import {MdVisibility} from "@react-icons/all-files/md/MdVisibility"
import {BiMedal} from "@react-icons/all-files/bi/BiMedal"
import {FaCopy} from "@react-icons/all-files/fa/FaCopy"
import platform from "platform"
import {PatreonPage} from "../PatreonPage"
import {navigate} from "gatsby"
import {useI18nLink} from "../../../components/i18n/I18nLink"
import {FaCogs} from "@react-icons/all-files/fa/FaCogs"
import {useBreadcrumb} from "../../../hooks/useBreadcrumb"
import {DownloadPatronVersionPage} from "./DownloadPatronVersionPage"
import {ReductionsCard} from "./ReductionsCard"
import {PatronNav} from "./PatronNav";
import {usePatreon} from "./usePatreon";
import {PatronInfo} from "../../../../../patreon-api/src/patron/Patron";
import {Badge} from "../../../../../patreon-api/src/badges/Types";

const TokenManager = (props: { currentToken: string }) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const i18nLink = useI18nLink()
  const patreon = usePatreon()
  const [state, setState] = useState({
    showPassword: false,
    showRegeneratePopup: false
  })
  const handleClickShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    })
  }


  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }
  const handlePopupClose = () => {
    setState({
      ...state,
      showRegeneratePopup: false,
    })
  }

  const handleRegenerate = () => {
    patreon.clearAll()
    dispatch(notification(t("patreon^Please log in again to generate a new key!"), "success"))
    navigate(i18nLink.getLink(PatreonPage.getUrl()))
  }

  return <React.Fragment>
    <Dialog
      open={state.showRegeneratePopup}
      onClose={handlePopupClose}
    >
      <DialogTitle id="alert-dialog-title">
        {t("patreon^Regenerate a private key?")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TransMdTypo>patreon^Reconnect to generate</TransMdTypo>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePopupClose}>{t("Cancel")}</Button>
        <Button onClick={handleRegenerate} autoFocus>
          {t("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
    <TransMdTypo gutterBottom>patreon^Access Recalbox premium features with your private key:</TransMdTypo>
    <Grid container css={{marginTop: "1em"}} alignItems={"center"} spacing={2}>
      <Grid item>
        <FormControl variant="outlined" size={"small"}>
          <InputLabel htmlFor="outlined-adornment-password">{t("patreon^Private key")}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={state.showPassword ? "text" : "password"}
            value={props.currentToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {state.showPassword ? <MdVisibilityOff/> : <MdVisibility/>}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Button startIcon={<FaCopy/>} variant={"outlined"} onClick={() => {
          navigator && navigator.clipboard.writeText(props.currentToken).then(() =>
            dispatch(notification(t("patreon^Private Key copied!"), "success")))
        }}>Copier</Button>
      </Grid>
      <Grid item>
        <Button startIcon={<FaCogs/>} variant={"outlined"} onClick={() => {
          setState({...state, showRegeneratePopup: true})
        }}>Regénérer</Button>
      </Grid>
    </Grid>

  </React.Fragment>
}


const RecalboxManager = (props: { currentToken: string }) => {

  const {t} = useTranslation()

  return <React.Fragment>
    <React.Fragment>
      <TransMdTypo gutterBottom>{t("patreon^Steps to save the private key")}</TransMdTypo>
      <Button color={"secondary"} variant={"outlined"}
              target={"_blank"}
              href={platform.os?.family?.indexOf("Windows") !== -1 ? "http://recalbox/#/patreon" : "http://recalbox.local/#/patreon"}>
        {t("patreon^Web manager recalbox 9.2-patron")}
      </Button>&nbsp;
      <Button color={"secondary"} variant={"outlined"}
              target={"_blank"}
              href={platform.os?.family?.indexOf("Windows") !== -1 ? "http://recalbox/token" : "http://recalbox.local/token"}>
        {t("patreon^Web manager recalbox 9.1")}
      </Button>
    </React.Fragment>
  </React.Fragment>
}

const UserInfo = () => {
  const patreon = usePatreon()
  const {t, i18n} = useTranslation()
  const [state, setState] = useState({patronInfo: undefined as PatronInfo | undefined, tierBadge: undefined as Badge | undefined})
  const dispatch = useDispatch()
  const i18nLink = useI18nLink()
  useEffect(() => {
    patreon.getUserInfo().then((patronInfo) => {
      patreon.getBadges().then((badges) => {
        const currentTier = patronInfo.tier_status
        const tierBadge = badges.find(b => b.unlocked && b.name.startsWith(`Boss Level ${currentTier}`))
        setState({patronInfo, tierBadge})
      }).catch(e => {
        dispatch(notification(t("patreon^We could not find data about your support to Recalbox."), "warning"))
        navigate(i18nLink.getLink(PatreonPage.getUrl()))
      })
    }).catch(e => {
      dispatch(notification(t("patreon^We could not find data about your support to Recalbox."), "warning"))
      navigate(i18nLink.getLink(PatreonPage.getUrl()))
    })
  }, [])
  console.log(state.patronInfo)
  return <React.Fragment>
    {state.patronInfo &&
      <Grid container xs={12}>
        <Grid item container>
          <Grid item>
            <Typography><span css={{fontWeight: "bold"}}>{t("patreon^Level")}:</span></Typography>
          </Grid>
          <Grid item css={{flexGrow: 4}}/>
          <Grid item>
            <Typography> <BiMedal/>{state.patronInfo.tiers[0].tier_title}</Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
            <Typography><span css={{fontWeight: "bold"}}>{t("patreon^Since")}:</span></Typography>
          </Grid>
          <Grid item css={{flexGrow: 4}}/>
          <Grid item>
            <Typography>{new Date(state.patronInfo.patron_since).toLocaleString(i18n.language, {
              month: "long",
              year: "numeric"
            })}</Typography>
          </Grid>
        </Grid>
        {state.tierBadge &&
        <Grid item container>
          <img css={{ objectFit: "contain", width: "100%", maxHeight: "100%"}} src={state.tierBadge.image}/>
        </Grid>
        }
        </Grid>
    }
  </React.Fragment>
}

export const ProfileGrid = () => {

  const patreon = usePatreon()
  const {t} = useTranslation()
  return <Grid container spacing={2}>
    <Grid item xs={12} md={9}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={"h3"}>{t("patreon^Private key")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TokenManager currentToken={patreon.currentToken()}/>
                </Grid>
                <Grid item xs={12}>
                  <RecalboxManager currentToken={patreon.currentToken()}/>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant={"h3"}>{t("patreon^Recalbox Patron Edition")}</Typography>
                </Grid>
                <Grid item>
                  <TransMdTypo
                    children={"patreon^Download Patron Edition for features"}/>
                </Grid>
                <Grid item>
                  <Button component={Link} color={"secondary"} variant={"outlined"}
                          to={DownloadPatronVersionPage.getUrl()}>
                    {t("patreon^Download Recalbox Patron Edition")}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <ReductionsCard/>
        </Grid>
      </Grid>

    </Grid>
    <Grid item xs={12} md={3}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant={"h3"}>{t("patreon^My Account")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <UserInfo/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}

const Profile = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(ProfilePage)

  useEffect(() => {
    if (location.search.indexOf("logmessage") !== -1) {
      dispatch(notification(t("patreon^You are now connected!"), "success"))
    }
  }, [])

  return <Layout title={t("patreon^My Patron Area")}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <PatronNav tab={"profile"}/>
        <ProfileGrid/>
      </ResponsiveContainer>
    </StarsImageContainer>
  </Layout>
}


const getUrl = (loggedIn?: boolean) => `/patreon/patron/profile/${loggedIn ? "?logmessage=1" : ""}`
export const ProfilePage: Page = {
  Page: Profile,
  getUrl,
  parentPage: () => PatreonPage,
  breadCrumb: {link: getUrl(false), name: "Profile"}
}
